import isSameDay from 'date-fns/isSameDay'
import getConfig from 'next/config'
import { toDateFormat } from 'lib/dateFormat'
import EventListingBumpDto from '@/interfaces/gql/EventListingBumpDto'
import EventDto from '@/interfaces/gql/EventDto'

const {
  publicRuntimeConfig: {
    RA_BUMPS_CONFIG: { BUMPED_EVENT_INDEX },
  },
} = getConfig()

export const insertBumpedPopularEvents = (
  data: EventDto[],
  bumps: EventListingBumpDto[]
): EventDto[] => {
  const bump = bumps?.[0]

  if (!bump || !bump.event) return data

  const bumpedEvent = mapBumpToEvent(bump, true)

  const bumpIndexInListings = data.findIndex(
    (e) => 'id' in e && e.id === bump.eventId
  )
  const isBumpInListings = bumpIndexInListings !== -1

  return handleBumps(isBumpInListings, bumpIndexInListings, data, bumpedEvent)
}

export const insertBumpedEventListings = (
  groupedData: { listingDate?: Date; items: Pick<EventDto, 'id' | 'date'>[] }[],
  bumps: EventListingBumpDto[]
) => {
  return groupedData.map((group) => {
    const listings: EventDto[] = [...(group.items as EventDto[])]
    const bumpedEventForDate = bumps.find((bump) =>
      isSameDay(toDateFormat(bump.date), toDateFormat(group.listingDate))
    )

    if (bumpedEventForDate) {
      const bumpIndexInListings = group.items.findIndex(
        (e) => e.id === bumpedEventForDate.eventId
      )
      const isBumpInListings = bumpIndexInListings !== -1

      const eventWithBumpInfo = mapBumpToEvent(bumpedEventForDate, false)

      return {
        ...group,
        items: handleBumps(
          isBumpInListings,
          bumpIndexInListings,
          listings,
          eventWithBumpInfo
        ),
      }
    }

    return group
  })
}

// event mapping logic | both popular and regular listings
const mapBumpToEvent = (
  bump: EventListingBumpDto,
  isPopular: boolean
): EventDto => ({
  ...bump.event,
  date: isPopular ? bump.event.date : bump.date,
  bump: {
    id: bump.id,
    clickUrl: bump.clickUrl,
    impressionUrl: bump.impressionUrl,
  },
})

// bump insertion logic for both popular and regular listings
const handleBumps = (
  isBumpInListings: boolean,
  bumpIndexInListings: number,
  listings: EventDto[],
  bumpedEvent: EventDto
) => {
  const updatedListings = [...listings]

  // If less than (BUMPED_EVENT_INDEX + 1) events for date do not insert the bump
  if (updatedListings.length < BUMPED_EVENT_INDEX + 1) {
    return updatedListings
  }

  // If the bumped event is already in the top (BUMPED_EVENT_INDEX + 1), just attach the bump info
  if (isBumpInListings && bumpIndexInListings <= BUMPED_EVENT_INDEX) {
    updatedListings[bumpIndexInListings] = bumpedEvent

    // If the bumped event already appears in the listings after the top (BUMPED_EVENT_INDEX + 1), move it to (BUMPED_EVENT_INDEX + 1) position
  } else if (isBumpInListings && bumpIndexInListings > BUMPED_EVENT_INDEX) {
    updatedListings.splice(bumpIndexInListings, 1)
    updatedListings.splice(BUMPED_EVENT_INDEX, 0, bumpedEvent)

    // If the bumped event is not in the listings yet due to pagination, insert it at (BUMPED_EVENT_INDEX + 1) position
  } else if (!isBumpInListings && updatedListings.length > BUMPED_EVENT_INDEX) {
    updatedListings.splice(BUMPED_EVENT_INDEX, 0, bumpedEvent)
  }

  return updatedListings
}
