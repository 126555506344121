import { createContext, Context, PropsWithChildren, useContext } from 'react'
import { useRouter } from 'next/router'
import arrayHasData from 'lib/arrayHasData'
import AreaDto from '@/interfaces/gql/AreaDto'
import EventType from '@/enums/event-type'
import EventListingDateQueryParameters from '@/enums/event-listing-date-query-parameters'
import { EventListingsType } from '@/enums/event-listings-type'
import EventListingsLocationType from '@/enums/event-listings-location-type'
import useLocalisedAreaName from '@/hooks/useLocalisedAreaName'
import { useServerTime } from '@/context/ServerTimeContext'
import {
  AreasFilterValue,
  EndDateFilterValue,
  FilterValue,
  StartDateFilterValue,
  LocationFilterValue,
} from './filter-values'

class EventListingsFilterContextState {
  public readonly area: AreasFilterValue

  public readonly [EventListingDateQueryParameters.StartDate]?: StartDateFilterValue

  public readonly [EventListingDateQueryParameters.EndDate]?: EndDateFilterValue

  public readonly [EventListingDateQueryParameters.ThisWeekend]?: boolean

  public readonly genre?: FilterValue<string[]>

  public readonly eventType?: FilterValue<EventType>

  public readonly location?: LocationFilterValue

  public readonly listingsType: EventListingsType

  public readonly locationType: EventListingsLocationType

  constructor(
    area: AreaDto,
    areaName: string,
    startDate: string,
    serverTime: Date,
    endDate: string,
    thisWeekend: string,
    genre: string | string[],
    eventType: EventType,
    listingsType: EventListingsType,
    locationType: EventListingsLocationType,
    location: LocationFilterValue
  ) {
    this.area = new AreasFilterValue(area, areaName)
    this.genre = genre
      ? new FilterValue<string[]>(Array.isArray(genre) ? genre : [genre])
      : undefined

    this.startDate = startDate
      ? new StartDateFilterValue(startDate, serverTime, area.ianaTimeZone)
      : undefined

    this.endDate = endDate ? new EndDateFilterValue(endDate) : undefined
    this.thisWeekend = !!thisWeekend
    this.eventType = Number.isNaN(eventType)
      ? undefined
      : new FilterValue<EventType>(eventType)

    this.listingsType = listingsType

    this.locationType = locationType

    this.location = location
  }

  get hasAppliedFilters(): boolean {
    return (
      arrayHasData(this.genre?.value) ||
      !!this.eventType?.value ||
      !!this.startDate?.value ||
      !!this.endDate?.value
    )
  }
}

const EventListingsFiltersContext: Context<EventListingsFilterContextState> =
  createContext({
    area: undefined,
    genre: undefined,
    [EventListingDateQueryParameters.StartDate]: undefined,
    [EventListingDateQueryParameters.EndDate]: undefined,
    [EventListingDateQueryParameters.ThisWeekend]: undefined,
    eventType: undefined,
    hasAppliedFilters: false,
    listingsType: EventListingsType.all,
    locationType: EventListingsLocationType.area,
  })

const useFiltersState = ({
  area,
  listingsType,
  locationType,
}: {
  area: AreaDto
  listingsType: EventListingsType
  locationType: EventListingsLocationType
}): EventListingsFilterContextState => {
  const { query } = useRouter()
  const serverTime = useServerTime()
  const areaName = useLocalisedAreaName({ area, country: area.country })

  const queryStartDate = query[
    EventListingDateQueryParameters.StartDate
  ] as string

  const queryEndDate = query[EventListingDateQueryParameters.EndDate] as string

  const thisWeekend = query[
    EventListingDateQueryParameters.ThisWeekend
  ] as string

  const eventType = parseInt(query.eventType as string, 10)

  const latitude = parseFloat(query.latitude as string)
  const longitude = parseFloat(query.longitude as string)
  const distance = query.distance as string
  const location =
    query.distance && query.latitude && query.longitude
      ? new LocationFilterValue(distance, latitude, longitude)
      : null

  return new EventListingsFilterContextState(
    area,
    areaName,
    queryStartDate,
    serverTime,
    queryEndDate,
    thisWeekend,
    query.genre,
    eventType,
    listingsType,
    locationType,
    location
  )
}

const EventListingsFiltersContextProvider = ({
  area,
  listingsType,
  locationType,
  children,
}: PropsWithChildren<EventListingsFilterContextProps>) => {
  const filtersState = useFiltersState({ area, listingsType, locationType })

  return (
    <EventListingsFiltersContext.Provider value={filtersState}>
      {children}
    </EventListingsFiltersContext.Provider>
  )
}

type EventListingsFilterContextProps = {
  area: AreaDto
  listingsType: EventListingsType
  locationType: EventListingsLocationType
}

const useEventListingsFiltersContext = () =>
  useContext(EventListingsFiltersContext)

export { useEventListingsFiltersContext, EventListingsFilterContextState }
export default EventListingsFiltersContextProvider
